import Utils from "./Utils.js";
import Config from "./Config.js";
import EventEmitter from "@onemorestudio/eventemitterjs";
export default class Menu extends EventEmitter {
  constructor() {
    super();
    this.menu = document.querySelector(".menu_button");
    this.menu.addEventListener("click", this.toggleMenu.bind(this));
    this.list = document.querySelector(".list");
    this.title = document.querySelector(".vertical_tag");
    // this.initContent();
  }

  async initContent() {
    const tags = await Utils.loadJson(`${Config.PATH}listMenu.php`);
    this.list.innerHTML = "";

    // get isHomepage in tags
    const param = await Utils.loadJson(`${Config.PATH}getHomepage.php`);
    console.log(param);
    this.homepage_id = parseInt(param.tag_id);
    const li = document.createElement("li");
    li.id = param.tag_id;
    li.textContent = "HOMEPAGE";
    this.list.appendChild(li);

    let separator = false;
    tags.forEach((tag) => {
      if (tag.type == "2" && !separator) {
        separator = true;
        const li = document.createElement("li");
        li.innerHTML = `<li class="separator">●</li>`;
        this.list.appendChild(li);
      }
      const li = document.createElement("li");
      li.textContent = tag.tag_value;
      li.id = tag.tag_id;
      this.list.appendChild(li);
    });

    this.list.addEventListener("click", (e) => {
      this.toggleMenu();
      if (e.target.id) this.emit("click", [e.target.id]);
    });
  }

  async setTitle(tag_id) {
    console.log(tag_id);
    // get tag name from tag_id
    const tags = await Utils.loadJson(`${Config.PATH}listMenu.php`);
    const tag = tags.find((tag) => tag.tag_id == tag_id);
    console.log("tag", tag);
    this.title.textContent = tag.tag_value;
    // return tag.tag_value;
  }

  toggleMenu() {
    document.body.classList.toggle("menu--open");
  }
}
