import Menu from "./Menu";
import PageContent from "./PageContent";

export default class App {
  constructor() {
    // this.menu = new Menu();
    // this.menu.addEventListener("click", this.onClickMenu.bind(this));
    // this.mainColumn = document.querySelector(".mainColumn");
    // this.pageContent = new PageContent(this.mainColumn, this.menu.homepage_id);
    // this.pageContent.addEventListener("click", this.onClickMenu.bind(this));
    // //
    // window.history.pushState({ id: this.menu.homepage_id }, "", ``);
    // window.onpopstate = (e) => {
    //   console.log(e.state);
    //   if (e.state) {
    //     this.pageContent._id = e.state.id;
    //     this.menu.setTitle(e.state.id);
    //   }
    // };
    this.initApp();
  }

  async initApp() {
    this.menu = new Menu();
    await this.menu.initContent();
    this.menu.addEventListener("click", this.onClickMenu.bind(this));
    this.mainColumn = document.querySelector(".mainColumn");
    this.pageContent = new PageContent(this.mainColumn, this.menu.homepage_id);
    this.pageContent.addEventListener("click", this.onClickMenu.bind(this));
    //
    window.history.pushState({ id: this.menu.homepage_id }, "", ``);
    window.onpopstate = (e) => {
      console.log(e.state);
      if (e.state) {
        this.pageContent._id = e.state.id;
        this.menu.setTitle(e.state.id);
      }
    };
  }

  onClickMenu(id) {
    this.pageContent._id = id;
    this.menu.setTitle(id);
    window.history.pushState({ id: id }, "", `?id=${id}`);
  }
}
