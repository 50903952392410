export default class Utils {
  constructor() {}

  static loadPage(url, content, callback) {
    fetch(url)
      .then((response) => response.text())
      .then((html) => {
        content.innerHTML = html;
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  }

  static loadJson(url, callback, debug = false) {
    return fetch(url)
      .then((response) => (debug ? response.text() : response.json()))
      .then((json) => {
        // console.log(json);
        if (debug) console.log(json);
        if (callback) callback(json);
        else return json;
      })
      .catch((error) => {
        console.warn(error);
      });
  }

  static postData(url, data, callback, debug = false) {
    console.log(JSON.stringify(data));
    // send json data to the server
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => (debug ? response.text() : response.json()))
      .then((json) => {
        if (debug) console.log(json);
        if (callback) callback(json);
        else return json;
      })
      .catch((error) => {
        console.warn(error);
      });
  }

  static getImageSize(url) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => {
        resolve({ w: img.width, h: img.height });
      };
      img.src = url;
    });
  }

  static getVideoSize(url) {
    return new Promise((resolve, reject) => {
      let video = document.createElement("video");
      video.onloadedmetadata = () => {
        resolve({ w: video.videoWidth, h: video.videoHeight });
      };
      video.src = url;
    });
  }

  static loadImage(url, callback) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = function () {
        if (callback) callback(img);
        else resolve(img);
      };
      img.src = url;
    });
  }

  static async saveFile(url, fileName, contentType) {
    try {
      const response = await fetch(url);
      const content = await response.blob();

      var a = document.createElement("a");
      var file = new Blob([content], { type: contentType });
      a.href = URL.createObjectURL(file);
      a.download = fileName;
      a.click();
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  }
}
